define("cassini/components/routes/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title "Profile"}}
  <div class="row mt-3 mt-sm-0">
    <div class="col-sm-8">
      <Routes::Settings::IndexRoute::Profile
        @user={{@user}}
        @userAttributes={{@userAttributes}}
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-sm-8">
      <h2 class="type--section">
        Email address
      </h2>
  
      <p data-test-settings-email>
        This is the email you use to log in to the NFN Analytics website:
        <strong>
          {{@user.email}}
        </strong>
      </p>
      <p>
        To change the email address you use to log in, or to set a different email to use
        for communication, go to your
        <OktaProfileLink>
          Nasdaq Signin profile page.
        </OktaProfileLink>
      </p>
    </div>
  </div>
  {{#if (feature-flag "deleteUser")}}
    <div class="row mt-4">
      <div class="col-sm-8">
        <Routes::Settings::IndexRoute::Delete @user={{@user}} />
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "LVfpYheK",
    "block": "{\"symbols\":[\"@user\",\"@userAttributes\"],\"statements\":[[1,[30,[36,0],[\"Profile\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"row mt-3 mt-sm-0\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[8,\"routes/settings/index-route/profile\",[],[[\"@user\",\"@userAttributes\"],[[32,1],[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row mt-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"type--section\"],[12],[2,\"\\n      Email address\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,\"data-test-settings-email\",\"\"],[12],[2,\"\\n      This is the email you use to log in to the NFN Analytics website:\\n      \"],[10,\"strong\"],[12],[2,\"\\n        \"],[1,[32,1,[\"email\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      To change the email address you use to log in, or to set a different email to use\\n      for communication, go to your\\n      \"],[8,\"okta-profile-link\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        Nasdaq Signin profile page.\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[\"deleteUser\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row mt-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n      \"],[8,\"routes/settings/index-route/delete\",[],[[\"@user\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"feature-flag\",\"if\"]}",
    "moduleName": "cassini/components/routes/settings/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});