define("cassini/components/learn-more-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card card--content-link" ...attributes>
    <div class="card-body">
      <p>
        The Nasdaq Fund Network (NFN) has the first dedicated database of unit investment
        trust research reports that gives finance professionals access to the information
        they need to make investment recommendations with confidence.
      </p>
      <p>
        NFN facilitates the collection and dissemination of performance, NAV, valuation,
        and strategy-level reference data for over 35,000 investable products to 100
        million plus investors.
      </p>
      <p>
        <a
          href="https://www.nasdaq.com/solutions/nasdaq-fund-network"
          class="btn btn-primary w-100"
          {{track-click "Learn More Clicked" (hash location=@location)}}
        >
          Learn More
        </a>
      </p>
    </div>
  </div>
  */
  {
    "id": "HqbbwcbR",
    "block": "{\"symbols\":[\"&attrs\",\"@location\"],\"statements\":[[11,\"div\"],[24,0,\"card card--content-link\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      The Nasdaq Fund Network (NFN) has the first dedicated database of unit investment\\n      trust research reports that gives finance professionals access to the information\\n      they need to make investment recommendations with confidence.\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      NFN facilitates the collection and dissemination of performance, NAV, valuation,\\n      and strategy-level reference data for over 35,000 investable products to 100\\n      million plus investors.\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,6,\"https://www.nasdaq.com/solutions/nasdaq-fund-network\"],[24,0,\"btn btn-primary w-100\"],[4,[38,1],[\"Learn More Clicked\",[30,[36,0],null,[[\"location\"],[[32,2]]]]],null],[12],[2,\"\\n        Learn More\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"track-click\"]}",
    "moduleName": "cassini/components/learn-more-card.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});