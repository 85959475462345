define("cassini/templates/settings/internal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8fLctLN4",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Internal\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"row mt-3 mt-sm-0\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"type--section\"],[12],[2,\"\\n      Features\\n    \"],[13],[2,\"\\n    \"],[8,\"internal-feature-checkbox\",[],[[\"@name\"],[\"basicFactSheets\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Basic fact sheets\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "cassini/templates/settings/internal.hbs"
  });

  _exports.default = _default;
});