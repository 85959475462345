define("cassini/templates/register/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KDebJizv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/register/index\",[],[[\"@onConfirm\"],[[30,[36,1],[[30,[36,0],[[32,0],\"register.confirm\"],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"transition-to-route\",\"fn\"]}",
    "moduleName": "cassini/templates/register/index.hbs"
  });

  _exports.default = _default;
});