define("cassini/utils/query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryParamsToString = queryParamsToString;
  _exports.stripQueryParams = stripQueryParams;

  // We'd like to use URLSearchParams, but the only place where we actually *need*
  // this is in IE, where URLSearchParams doesn't exist anyway...
  function queryParamsToString(queryParams) {
    /**
     * Converts query parameters to their string equivalent.
     * @type {string}
     */
    var result = [];
    Object.keys(queryParams).forEach(function (param) {
      if (param) {
        result.push("".concat(encodeURIComponent(param), "=").concat(encodeURIComponent(queryParams[param])));
      }
    });
    return result.join("&");
  }

  function stripQueryParams(url) {
    // TODO: Use URL?
    var e = document.createElement("A");
    e.href = url;
    var pathname = e.pathname;
    return pathname[0] === "/" ? pathname : "/".concat(pathname);
  }
});