define("cassini/components/site/cannon-footer", ["exports", "@nfn/cannon/components/site/cannon-footer"], function (_exports, _cannonFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cannonFooter.default;
    }
  });
});