define("cassini/templates/register/terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cnuyLp1t",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/register/terms\",[],[[\"@onComplete\"],[[32,0,[\"onComplete\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/register/terms.hbs"
  });

  _exports.default = _default;
});