define("cassini/templates/top-downloads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vb0t1VMu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/top-downloads\",[],[[\"@model\"],[[32,0,[\"model\",\"products\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/top-downloads.hbs"
  });

  _exports.default = _default;
});