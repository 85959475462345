define("cassini/templates/register/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kpf2Obk6",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"🎉 Sign up complete\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"col my-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[2,\"\\n        Welcome to the Nasdaq Fund Network.\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          Enjoy your free access to our daily reports and analysis.\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          Use our screener to compare UITs strategies and download performance, expenses\\n          and portfolio PDF reports.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,\"data-test-link\",\"view-reports\"],[14,0,\"my-4 text-center\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-primary w-50\"]],[[\"@route\"],[\"screener\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Go to screener\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "cassini/templates/register/complete.hbs"
  });

  _exports.default = _default;
});