define("cassini/validations/base", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateCode = validateCode;
  _exports.validateEmail = validateEmail;
  _exports.validatePasswordStrength = validatePasswordStrength;

  function validateEmail() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      missing: "Enter your email address, please."
    },
        missing = _ref.missing;

    return [(0, _validators.validatePresence)({
      presence: true,
      message: missing
    }), (0, _validators.validateFormat)({
      type: "email",
      message: "This doesn't look like a valid email address."
    })];
  }

  function validateCode() {
    var codeType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "confirmation";
    return [(0, _validators.validatePresence)({
      presence: true,
      message: "Enter your ".concat(codeType, " code.")
    }), (0, _validators.validateLength)({
      is: 6
    }), (0, _validators.validateNumber)({
      positive: true,
      integer: true
    })];
  }

  function validatePasswordStrength() {
    return [(0, _validators.validatePresence)({
      presence: true,
      message: "You need a password."
    }), (0, _validators.validateLength)({
      min: 8,
      message: "We take your security seriously. Your password needs to be at least 8 characters."
    }), (0, _validators.validateFormat)({
      regex: /^[\S]+$/,
      message: "Sorry, your password cannot contain spaces."
    })];
  }
});