define("cassini/validations/download-sample", ["exports", "cassini/validations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: (0, _base.validateEmail)({
      missing: "Your email is required to download."
    })
  };
  _exports.default = _default;
});