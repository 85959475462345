define("cassini/initializers/site-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    var loader = document.getElementById("site-loader");

    if (loader) {
      loader.parentElement.removeChild(loader);
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});