define("cassini/validations/settings/index-route/profile", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validateLength)({
      max: 30
    })],
    lastName: [(0, _validators.validateLength)({
      max: 150
    })]
  };
  _exports.default = _default;
});