define("cassini/templates/login/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h0RN1f4K",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"w-100 text-center\"],[12],[2,\"\\n  \"],[8,\"login-redirect\",[],[[\"@defaultNext\"],[\"screener\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\",\"@size\"],[\"spinner\",true,\"5x\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/login/redirect.hbs"
  });

  _exports.default = _default;
});