define("cassini/components/routes/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title "Not found"}}
  <div class="d-flex align-items-center vh-100 w-100 gradient-1-bg" data-test-notfound>
    <div class="w-100 text-center">
      <h1 class="text-white">
        Page not found.
      </h1>
      <p class="text-white">
        We can't seem to find the page you're looking for.
        <Emoji @name="disappointed" />
      </p>
      <p class="my-4">
        <LinkTo @route="index" class="btn btn-light">
          Go Home
        </LinkTo>
      </p>
    </div>
  </div>
  */
  {
    "id": "2zrYUuXi",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Not found\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"d-flex align-items-center vh-100 w-100 gradient-1-bg\"],[14,\"data-test-notfound\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-100 text-center\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-white\"],[12],[2,\"\\n      Page not found.\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"text-white\"],[12],[2,\"\\n      We can't seem to find the page you're looking for.\\n      \"],[8,\"emoji\",[],[[\"@name\"],[\"disappointed\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"my-4\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-light\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Go Home\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "cassini/components/routes/not-found.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});