define("cassini/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pcLh3uyq",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[10,\"title\"],[12],[2,\"\\n  \"],[1,[32,0,[\"model\",\"title\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[30,[36,0],[[32,0,[\"model\",\"ogTitle\"]],[32,0,[\"model\",\"title\"]]],null]],[12],[13],[2,\"\\n\"],[10,\"meta\"],[14,3,\"twitter:title\"],[15,\"content\",[30,[36,0],[[32,0,[\"model\",\"twTitle\"]],[32,0,[\"model\",\"title\"]]],null]],[12],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\",\"meta\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[15,3,[32,1,[\"name\"]]],[15,\"property\",[32,1,[\"property\"]]],[15,\"content\",[32,1,[\"content\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "moduleName": "cassini/templates/head.hbs"
  });

  _exports.default = _default;
});