define("cassini/templates/settings/security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lifpzSS5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/settings/security\",[],[[\"@user\"],[[32,0,[\"model\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/settings/security.hbs"
  });

  _exports.default = _default;
});