define("cassini/templates/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mttwHlq2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/product\",[],[[\"@product\"],[[32,0,[\"model\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/product.hbs"
  });

  _exports.default = _default;
});