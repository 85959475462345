define("cassini/templates/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xk3HdBs0",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"routes/settings/index\",[],[[\"@user\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/settings/index.hbs"
  });

  _exports.default = _default;
});