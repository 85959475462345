define("cassini/components/sliding-popup/head", ["exports", "@nfn/cannon/components/sliding-popup/head"], function (_exports, _head) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _head.default;
    }
  });
});