define("cassini/components/site-dropdown", ["exports", "@nfn/cannon/components/site-dropdown"], function (_exports, _siteDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _siteDropdown.default;
    }
  });
});