define("cassini/validations/register/confirm", ["exports", "cassini/validations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    code: (0, _base.validateCode)("confirmation")
  };
  _exports.default = _default;
});