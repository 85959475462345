define("cassini/validations/register/index", ["exports", "cassini/validations/base", "ember-changeset-validations/validators"], function (_exports, _base, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validateChecked() {
    return function (key, newValue) {
      return newValue === true || "You must agree to the Terms and Conditions and Privacy Policy.";
    };
  }

  var _default = {
    email: (0, _base.validateEmail)(),
    bizActivity: (0, _validators.validatePresence)({
      presence: true,
      message: "Please choose an option."
    }),
    aum: (0, _validators.validatePresence)({
      presence: true,
      message: "Please choose an option."
    }),
    aumUitPercent: (0, _validators.validatePresence)({
      presence: true,
      message: "Please choose an option."
    }),
    uitUse: (0, _validators.validatePresence)({
      presence: true,
      message: "Please choose an option."
    }),
    tosAgree: validateChecked()
  };
  _exports.default = _default;
});