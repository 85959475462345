define("cassini/templates/screener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NceW8GMx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/screener\",[],[[\"@counts\",\"@results\",\"@sort\",\"@page\",\"@query\",\"@search\",\"@onSortChanged\",\"@onQueryChanged\",\"@onSearchChanged\",\"@onSearchReset\"],[[32,0,[\"model\",\"counts\"]],[32,0,[\"model\",\"products\"]],[32,0,[\"sort\"]],[32,0,[\"page\"]],[32,0,[\"query\"]],[32,0,[\"search\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"sort\"]]],null]],null],[30,[36,1],[[30,[36,0],[[32,0,[\"query\"]]],null]],null],[30,[36,1],[[30,[36,0],[[32,0,[\"search\"]]],null]],null],[30,[36,1],[[30,[36,0],[[32,0,[\"search\"]]],null],\"\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "moduleName": "cassini/templates/screener.hbs"
  });

  _exports.default = _default;
});