define("cassini/templates/compare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wKqaPS7p",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/compare\",[],[[\"@results\",\"@sort\",\"@page\",\"@type\",\"@perfClass\",\"@onSortChanged\"],[[32,0,[\"model\",\"products\"]],[32,0,[\"sort\"]],[32,0,[\"page\"]],[32,0,[\"type\"]],[32,0,[\"model\",\"perfClass\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"sort\"]]],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "moduleName": "cassini/templates/compare.hbs"
  });

  _exports.default = _default;
});