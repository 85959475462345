define("cassini/validations/settings/index-route/email", ["exports", "cassini/validations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: (0, _base.validateEmail)()
  };
  _exports.default = _default;
});