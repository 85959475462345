define("cassini/components/error-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @message}}
    <div class="alert alert-danger" role="alert" data-test-error-message ...attributes>
      {{@message}}
    </div>
  {{/if}}
  */
  {
    "id": "QqPpceo7",
    "block": "{\"symbols\":[\"&attrs\",\"@message\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"alert alert-danger\"],[24,\"role\",\"alert\"],[24,\"data-test-error-message\",\"\"],[17,1],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "cassini/components/error-alert.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});