define("cassini/templates/privacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ve1bfora",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"d-flex align-items-center vh-100 w-100\"],[14,\"data-test-auth-page\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-100 text-center text-primary\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      Redirecting to\\n      \"],[10,\"a\"],[14,6,\"https://www.nasdaq.com/privacy-statement\"],[12],[2,\"\\n        https://www.nasdaq.com/privacy-statement\\n      \"],[13],[2,\"\\n      ...\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\",\"@size\"],[\"spinner\",true,5]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/privacy.hbs"
  });

  _exports.default = _default;
});