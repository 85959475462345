define("cassini/templates/logout/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkubD0E9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"logout-redirect\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\",\"@size\"],[\"spinner\",true,\"5x\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/templates/logout/index.hbs"
  });

  _exports.default = _default;
});