define("cassini/utils/slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slugify;
  // Mostly a port of:
  // https://docs.djangoproject.com/en/2.1/_modules/django/utils/text/#slugify
  // Remove non-alphanumeric
  var RX1 = /[^\w\s-]/g; // Remove successive/trailing hyphens

  var RX2 = /[-\s]+/g;

  function slugify(value) {
    // value = value.normalize("NFKD"); // Damn IE
    value = value.replace(RX1, "").trim().toLowerCase();
    return value.replace(RX2, "-");
  }
});