define("cassini/templates/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TqdXP5oE",
    "block": "{\"symbols\":[\"base\"],\"statements\":[[8,\"routes/products\",[],[[\"@title\",\"@titleToken\",\"@search\",\"@sort\",\"@page\",\"@assetClass\",\"@status\",\"@pathKey\",\"@products\",\"@listComponentName\",\"@onSortChanged\",\"@onSearchChanged\",\"@onSearchReset\"],[[32,0,[\"model\",\"title\"]],[32,0,[\"model\",\"titleToken\"]],[32,0,[\"search\"]],[32,0,[\"sort\"]],[32,0,[\"page\"]],[32,0,[\"model\",\"assetClass\"]],[32,0,[\"model\",\"status\"]],[32,0,[\"model\",\"pathKey\"]],[32,0,[\"model\",\"products\"]],[30,[36,0],[\"routes/products/\",[32,0,[\"model\",\"assetClass\"]]],null],[30,[36,2],[[30,[36,1],[[32,0,[\"sort\"]]],null]],null],[30,[36,2],[[30,[36,1],[[32,0,[\"search\"]]],null]],null],[30,[36,2],[[30,[36,1],[[32,0,[\"search\"]]],null],\"\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"list\"]],[],[[\"@status\",\"@products\"],[[32,0,[\"model\",\"status\"]],[32,0,[\"model\",\"products\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"mut\",\"fn\"]}",
    "moduleName": "cassini/templates/products.hbs"
  });

  _exports.default = _default;
});