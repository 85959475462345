define("cassini/components/routes/register/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title "Confirm email address"}}
  <div class="col">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <h1 class="type--section">
          Check your email
        </h1>
        <p>
          Follow the instructions we've sent to your email to finish the registration
          process.
        </p>
        <p>
          Haven't received our email? Try your spam folder.
        </p>
      </div>
    </div>
  </div>
  */
  {
    "id": "dyUQ7E0v",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Confirm email address\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"type--section\"],[12],[2,\"\\n        Check your email\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        Follow the instructions we've sent to your email to finish the registration\\n        process.\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        Haven't received our email? Try your spam folder.\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "cassini/components/routes/register/confirm.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});