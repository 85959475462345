define("cassini/components/sponsored-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="badge badge-warning sponsored-badge" ...attributes data-test-sponsored>
    Sponsored
  </span>
  */
  {
    "id": "9npE6K8Y",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"span\"],[24,0,\"badge badge-warning sponsored-badge\"],[17,1],[24,\"data-test-sponsored\",\"\"],[12],[2,\"\\n  Sponsored\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "cassini/components/sponsored-badge.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});