define("cassini/components/routes/login/index-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title "Log in"}}
  <div class="row justify-content-center">
    <div class="col-lg-7">
      <h2 class="type--section my-4">
        Log in
      </h2>
      <ul>
        <li>
          Access to in-depth research, performance, and proprietary comparative analysis
        </li>
        <li>
          More informed investment recommendations and reduced unsuitability risk
        </li>
        <li>
          Improved compliance with investor suitability and fiduciary standards
        </li>
        <li>
          Time saved that can be used on client relationships
        </li>
      </ul>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-7">
          <LoginButton @next={{@next}} class="w-100" data-test-login />
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <h2 class="type--section my-4">
        Don't have an account?
      </h2>
      <p>
        <LinkTo @route="register">
          Create an account with Nasdaq
        </LinkTo>
        for full access to evaluate UITs quickly and efficiently. It's free and it only
        takes a minute.
      </p>
    </div>
  </div>
  */
  {
    "id": "Q6utIOXR",
    "block": "{\"symbols\":[\"@next\"],\"statements\":[[1,[30,[36,0],[\"Log in\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"type--section my-4\"],[12],[2,\"\\n      Log in\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        Access to in-depth research, performance, and proprietary comparative analysis\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        More informed investment recommendations and reduced unsuitability risk\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        Improved compliance with investor suitability and fiduciary standards\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        Time saved that can be used on client relationships\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-9 col-lg-7\"],[12],[2,\"\\n        \"],[8,\"login-button\",[[24,0,\"w-100\"],[24,\"data-test-login\",\"\"]],[[\"@next\"],[[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"type--section my-4\"],[12],[2,\"\\n      Don't have an account?\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"register\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Create an account with Nasdaq\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      for full access to evaluate UITs quickly and efficiently. It's free and it only\\n      takes a minute.\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "cassini/components/routes/login/index-route.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});