define("cassini/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XuWvF6OT",
    "block": "{\"symbols\":[\"layout\"],\"statements\":[[1,[30,[36,0],[\"Get in touch\"],null]],[2,\"\\n\"],[8,\"page-layout\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"head\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Get in touch\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"page\"]],[[4,[38,1],null,null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"If you have questions about our services or need support, contact us during\\n      business hours (9am – 6pm ET) and we will do our best to assist you.\"],[13],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[2,\"Questions & Support\"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,6,\"mailto:NFNOps@nasdaq.com\"],[12],[2,\"NFNOps@nasdaq.com\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[2,\"Business Inquiries\"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,6,\"mailto:NFNOps@nasdaq.com\"],[12],[2,\"NFNOps@nasdaq.com\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"track-links\"]}",
    "moduleName": "cassini/templates/contact.hbs"
  });

  _exports.default = _default;
});