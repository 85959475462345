define("cassini/components/metric-val", ["exports", "@nfn/cannon/components/metric-val"], function (_exports, _metricVal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _metricVal.default;
    }
  });
});