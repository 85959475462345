define("cassini/utils/cognito-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_MESSAGES = void 0;
  _exports.getErrorMessage = getErrorMessage;
  _exports.isIgnorableError = isIgnorableError;
  var DEFAULT_MESSAGES = {
    UserNotFoundException: "No user exists with that email address."
  };
  _exports.DEFAULT_MESSAGES = DEFAULT_MESSAGES;

  function getErrorMessage(err) {
    var messages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_MESSAGES;
    return messages[err.code] || err.message || err;
  }

  function isIgnorableError(err) {
    return err.code === "InvalidParameterException" || err.code === "UserNotFoundException";
  }
});