define("cassini/components/format-opt-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @date}}
    {{moment-format @date (or @format "M/D/YY")}}
  {{else}}
    {{or @na "N/A"}}
  {{/if}}
  */
  {
    "id": "1RgoX7tQ",
    "block": "{\"symbols\":[\"@na\",\"@format\",\"@date\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,3],[30,[36,0],[[32,2],\"M/D/YY\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1],\"N/A\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"moment-format\",\"if\"]}",
    "moduleName": "cassini/components/format-opt-date.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});