define("cassini/templates/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IbKh/H2h",
    "block": "{\"symbols\":[\"layout\"],\"statements\":[[8,\"page-layout\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"head\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    User account\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"no-storage-alert\",[],[[],[]],null],[2,\"\\n  \"],[8,[32,1,[\"page\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "cassini/templates/register.hbs"
  });

  _exports.default = _default;
});