define("cassini/components/content-link/sub", ["exports", "@nfn/cannon/components/content-link/sub"], function (_exports, _sub) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sub.default;
    }
  });
});