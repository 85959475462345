define("cassini/validations/settings/security", ["exports", "ember-changeset-validations/validators", "cassini/validations/base"], function (_exports, _validators, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    oldPassword: (0, _validators.validatePresence)({
      presence: true,
      message: "Please enter your current password."
    }),
    password: (0, _base.validatePasswordStrength)(),
    password2: [(0, _validators.validatePresence)({
      presence: true,
      message: "Please enter your new password."
    }), (0, _validators.validateConfirmation)({
      on: "password",
      message: "Passwords don't match."
    })]
  };
  _exports.default = _default;
});