define("cassini/components/routes/settings/security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title "Security"}}
  <div class="row mt-3 mt-sm-0">
    <div class="col-sm-8">
      <h2 class="type--section">
        Change Password
      </h2>
      <p>
        You can change your password, security image, or add extra verification to your
        account on your
        <OktaProfileLink>
          Nasdaq Signin profile page.
        </OktaProfileLink>
      </p>
    </div>
  </div>
  */
  {
    "id": "gqAsDZYM",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Security\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"row mt-3 mt-sm-0\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"type--section\"],[12],[2,\"\\n      Change Password\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      You can change your password, security image, or add extra verification to your\\n      account on your\\n      \"],[8,\"okta-profile-link\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        Nasdaq Signin profile page.\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "cassini/components/routes/settings/security.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});