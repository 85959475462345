define("cassini/components/collapse-panel", ["exports", "@nfn/cannon/components/collapse-panel"], function (_exports, _collapsePanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _collapsePanel.default;
    }
  });
});